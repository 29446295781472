<div class="app-header header-shadow" style="position: fixed;
top: 0;z-index:9999999;width:100%;background:#fff">
    <div class="app-header__logo">
        <a routerLink="/dashboard/dashboard"><img src="{{baseUrl+configuration?.logoWithName}}"
                style="width: 236px;margin-left: 6px;"></a>
        <!-- <p class="subHead">KOCHAS MUNICIPAL</p> -->
        <!-- <div class="header__pane ml-auto">
            <div>
                <button type="button" class="hamburger close-sidebar-btn hamburger--elastic"
                    data-class="closed-sidebar">
                    <span class="hamburger-box">
                        <span class="hamburger-inner"></span>
                    </span>
                </button>
            </div>
        </div> -->
    </div>
    <div class="app-header__mobile-menu">
        <!--  <div class="mm-trigger">
            <button type="button" class="hamburger hamburger--elastic mobile-toggle-nav">
                <span class="hamburger-box">
                    <span class="hamburger-inner"></span>
                </span>
            </button>
        </div>-->
    </div>
    <div class="app-header__menu">
        <ul class="vertical-nav-menu settopheadermenu">
            <li> <a routerLink="/dashboard/users_profile" class="setliback1"><i class="fa fa-user"
                        aria-hidden="true"></i> &nbsp;{{'headerProfile' | translate}} </a></li>
            <li> <a (click)="logoutFun()" class="setnewli"><i class="fa fa-sign-out"
                        aria-hidden="true"></i>&nbsp;{{'headerLogOut' | translate}}</a></li>
        </ul>
    </div>
    <div class="hamburger-menu">
        <input id="menu__toggle" type="checkbox" />
        <label class="menu__btn" for="menu__toggle">
            <span></span>
        </label>
        <ul class="menu__box">
            <ul class="vertical-nav-menu" style=" overflow-y: scroll;  height: 600px;">
                <li class="app-sidebar__heading">{{'sbAdminControl' | translate}}</li>
                <li class="mm-active">
                    <a> <i class="metismenu-icon pe-7s-id"></i>Dashboard<i
                        class="metismenu-state-icon pe-7s-angle-down caret-left"></i>
                </a>
                <ul>
                    <li class="mm-active">
                        <a routerLink="/dashboard/dashboard">
                            <i class="metismenu-icon"></i>Property Dashboard </a>
                    </li>
                    <li >
                        <a routerLink="/dashboard/mobiledashboard">
                            <i class="metismenu-icon"></i>Mobile Dashboard</a>
                    </li>
                    <li >
                        <a routerLink="/dashboard/shopdashboard">
                            <i class="metismenu-icon"></i>ShopRent Dashboard</a>
                    </li>
                    <li>
                        <a routerLink="/dashboard/tradedashboard">
                            <i class="metismenu-icon"></i>Trade-License Dashboard</a>
                    </li>
               
                  
                 
                </ul>
                </li>
                <li *ngIf="getModuleAccess('UserProfile')" class="app-sidebar__heading">{{'sbUserManagement' |
                    translate}}</li>
                <li *ngIf="getModuleAccess('UserProfile')">
                    <a> <i class="metismenu-icon pe-7s-id"></i>{{'sbUserProfile' | translate}}<i
                            class="metismenu-state-icon pe-7s-angle-down caret-left"></i>
                    </a>
                    <ul>
                        <li *ngIf="getMenuAccess('1')">
                            <a routerLink="/dashboard/Organisation">
                                <i class="metismenu-icon"></i>chk {{'sbOrganization' | translate}} </a>
                        </li>
                        <li *ngIf="getMenuAccess('41')">
                            <a routerLink="/user_management/user-type">
                                <i class="metismenu-icon"></i>User Type</a>
                        </li>
                        <li *ngIf="getMenuAccess('35')">
                            <a routerLink="/user_management/employee_list">
                                <i class="metismenu-icon"></i>{{'sbMunicipalUser' | translate}}</a>
                        </li>
                        <li *ngIf="getMenuAccess('36')">
                            <a routerLink="/user_management/project-manager">
                                <i class="metismenu-icon"></i>{{'sbProjectManager' | translate}}</a>
                        </li>
                        <li>
                            <a routerLink="/user_management/team_list">
                                <i class="metismenu-icon"></i>Team Leader</a>
                        </li>
                        <li *ngIf="getMenuAccess('37')">
                            <a routerLink="/user_management/tax-collector">
                                <i class="metismenu-icon"></i>{{'sbTaxCollector' | translate}}</a>
                        </li>
                         <li>
                            <a routerLink="/user_management/track-user">
                                <i class="metismenu-icon"></i>Track User</a>
                        </li>


                        <li *ngIf="getMenuAccess('42')">
                            <a routerLink="/user_management/data-entry">
                                <i class="metismenu-icon"></i>Data Entry</a>
                        </li>
                        <!-- <li *ngIf="getMenuAccess('3')">
                            <a routerLink="/user_management/reset_password">
                                <i class="metismenu-icon"></i> Reset Password </a>
                        </li> -->
                    </ul>
                </li>

                <!-- <li class="app-sidebar__heading">Id Card MANAGEMENT</li> -->
                <!-- {{'sbSafManagement' | translate}} -->
                <li>
                    <a>
                        <i class="metismenu-icon pe-7s-id"></i>Employee ID Card<i
                            class="metismenu-state-icon pe-7s-angle-down caret-left"></i>
                    </a>
                    <ul>
                        <li>
                            <a routerLink="/user_management/identity-cards">
                                <i class="metismenu-icon"> </i>View List</a>
                        </li>
                    </ul>
                </li>


                <li *ngIf="getModuleAccess('Masters') || getModuleAccess('9') || getMenuAccess('WALLET') || getModuleAccess('8') || getModuleAccess('SAFApplication') || getModuleAccess('SAF') || getModuleAccess('SAFSetup')&&configuration?.operationsMgmt!='Ramnagar'&&loggedInObj.type!='MC'&&loggedInObj.type!='ACCOUNT'" class="app-sidebar__heading" class="app-sidebar__heading">PROPERTY MANAGEMENT</li>
                <!-- {{'sbSafManagement' | translate}} -->
                <li *ngIf="getModuleAccess('SAFSetup')">
                    <a>
                        <i class="metismenu-icon pe-7s-config"></i>{{'sbSafSetup' | translate}}<i
                            class="metismenu-state-icon pe-7s-angle-down caret-left"></i>
                    </a>
                    <ul>
                        <li *ngIf="getMenuAccess('4')">
                            <a routerLink="/avr-range/avr-list">
                                <i class="metismenu-icon"> </i>{{'sbArvRange' | translate}} </a>
                        </li>
                        <!-- <li>
                            <a routerLink="/other-tax/other-tax-list">
                                <i class="metismenu-icon"></i>Other Tax </a>
                        </li> -->
                        <!-- <li>
                            <a routerLink="">
                                <i class="metismenu-icon"></i>Penalty Master </a>
                        </li> -->
                        <!-- <li>
                            <a routerLink="/construction_type_list">
                                <i class="metismenu-icon"></i>Vacant Land Rate </a>
                        </li> -->
                        <li *ngIf="getMenuAccess('5')">
                            <a routerLink="/masters/vacantLandRate">
                                <i class="metismenu-icon"></i>{{'sbVacantLandRate' | translate}}</a>
                        </li>
                        <!-- <li>
                            <a routerLink="/saf/rate-setup">
                                <i class="metismenu-icon"></i> Rate Setup </a>
                        </li> -->
                        <li *ngIf="getMenuAccess('15')">
                            <a routerLink="/saf-setup/rebate">
                                <i class="metismenu-icon"></i> {{'sbRebate' | translate}} </a>
                        </li>
                        <li *ngIf="getMenuAccess('16')">
                            <a routerLink="/masters/occupancy-type-factor">
                                <i class="metismenu-icon"></i>{{'sbOccupancyTypeFactor' | translate}}</a>
                        </li>
                        <li *ngIf="getMenuAccess('17')">
                            <a routerLink="/masters/non-residential-usage-factor">
                                <i class="metismenu-icon"></i>{{'sbNonResidentialUsageFactor' | translate}}</a>
                        </li>
                        <li *ngIf="getMenuAccess('18')">
                            <a routerLink="/masters/penalty">
                                <i class="metismenu-icon"></i>{{'sbPenalty' | translate}}</a>
                        </li>
                        <li *ngIf="getMenuAccess('19')">
                            <a routerLink="/masters/floor-ratable-area">
                                <i class="metismenu-icon"></i>{{'sbFloorRatableArea' | translate}}</a>
                        </li>
                        <li *ngIf="getMenuAccess('20')">
                            <a routerLink="/masters/property-tax">
                                <i class="metismenu-icon"></i>{{'sbPropertyTax' | translate}}</a>
                        </li>
                        <li *ngIf="getMenuAccess('27')">
                            <a routerLink="/masters/property-list">
                                <i class="metismenu-icon"></i>{{'sbPropertyType' | translate}}</a>
                        </li>
                        <li *ngIf="getMenuAccess('27')">
                            <a routerLink="/saf-setup/other-charge">
                                <i class="metismenu-icon"></i>{{'sbOtherCharge' | translate}}</a>
                        </li>
                    </ul>
                </li>
                <li *ngIf="getModuleAccess('SAF')">
                    <a href="#">
                        <i class="metismenu-icon pe-7s-note2"></i> {{'sbSAF' | translate}} <i
                            class="metismenu-state-icon pe-7s-angle-down caret-left"></i>
                    </a>
                    <ul>
                        <li *ngIf="getMenuAccess('6')">
                            <a routerLink="/saf/saf-calculations">
                                <i class="metismenu-icon"> </i>{{'sbNewAssesment' | translate}}</a>
                            <a routerLink="/saf/reassessment">
                                <i class="metismenu-icon"> </i>Reassessment</a>
                                <a routerLink="/saf/Estimated">
                                    <i class="metismenu-icon"> </i>Estimated Property</a>
                        </li>
                        <!-- <li>
                            <a routerLink="">
                                <i class="metismenu-icon"></i>Reassesment </a>
                        </li>
                        <li>
                            <a>
                                <i class="metismenu-icon"> </i>SAF Calculations</a>
                        </li> -->
                    </ul>
                </li>
                <li *ngIf="getModuleAccess('SAFApplication')">
                    <a href="#">
                        <i class="metismenu-icon pe-7s-plugin"></i>{{'sbSAFApplication' | translate}} <i
                            class="metismenu-state-icon pe-7s-angle-down caret-left"></i>
                    </a>
                    <ul>
                        <li *ngIf="getMenuAccess('7')">
                            <a routerLink="/saf/property-lists">
                                <i class="metismenu-icon"></i>{{'sbPropertyList' | translate}}</a>
                        </li>
                        <li>
                            <a routerLink="/saf/locate_property">
                                <i class="metismenu-icon"></i>Locate Property</a>
                        </li>
                        <li *ngIf="getMenuAccess('38')">
                            <a routerLink="/saf/payments">
                                <i class="metismenu-icon"></i>{{'sbCollections' | translate}}</a>
                        </li>
                        <li *ngIf="getMenuAccess('39')">
                            <a routerLink="/saf/demands">
                                <i class="metismenu-icon"></i>{{'sbDemands' | translate}}</a>
                        </li>
                        <li *ngIf="getMenuAccess('47')">
                            <a routerLink="/saf/edit-property-request">
                                <i class="metismenu-icon"></i>Edit Property Request</a>
                        </li>
                        <li *ngIf="getMenuAccess('PROPERTY_DELETEREQUEST')">
                            <a routerLink="/saf/delete-request">
                                <i class="metismenu-icon"></i>Delete Request</a>
                        </li>
                        <li *ngIf="getMenuAccess('47')">
                            <a routerLink="/saf/reassessment-list">
                                <i class="metismenu-icon"></i>Reassessment List</a>
                        </li>
                        <li *ngIf="getMenuAccess('47')">
                            <a routerLink="/saf/mutation-list">
                                <i class="metismenu-icon"></i>Mutation List</a>
                        </li>
                        <li  *ngIf="getMenuAccess('LEGACY_LIST')">
                            <a routerLink="/saf/legacylist">
                                <i class="metismenu-icon"></i>Legacy List</a>
                        </li>
                       
                        <li>
                            <a routerLink="/saf/fixedarv-list">
                                <i class="metismenu-icon"></i>Fixed ARV List</a>
                        </li>
                        <li  *ngIf="getMenuAccess('REJECTED_PAYMENT_LIST')">
                            <a routerLink="/saf/rejectedlist">
                                <i class="metismenu-icon"></i>Rejected Payment</a>
                        </li>
                        <li *ngIf="getMenuAccess('47')">
                            <a routerLink="/saf/payment-list">
                                <i class="metismenu-icon"></i>Update Payment Mode</a>
                        </li>
                        <!-- <li>
                            <a routerLink="/saf/search_application">
                                <i class="metismenu-icon"></i> Search Application </a>
                        </li> -->
                        <li *ngIf="getMenuAccess('46')">
                            <a routerLink="/saf/survey_and_tax">
                                <i class="metismenu-icon"></i>Survey And Tax</a>
                        </li>
                    </ul>
                </li>
                <li>
                    <a href="#">
                        <i class="metismenu-icon pe-7s-user"></i>Mobile Tower<i
                            class="metismenu-state-icon pe-7s-angle-down caret-left"></i>
                    </a>
                    <ul>
                        <li *ngIf="getMenuAccess('39')">
                            <a routerLink="/mobile-tower/mobile-tower-tax">
                                <i class="metismenu-icon"></i>Mobile Tower Tax</a>
                        </li>
                        <li *ngIf="getMenuAccess('39')">
                            <a routerLink="/mobile-tower/mobile-tower-saf">
                                <i class="metismenu-icon"></i>Mobile Tower SAF</a>
                        </li>
                    </ul>
                </li>
                <li *ngIf="getModuleAccess('8')">
                    <a href="#">
                        <i class="metismenu-icon pe-7s-user"></i>Accounting <i
                            class="metismenu-state-icon pe-7s-angle-down caret-left"></i>
                    </a>
                    <ul>
                        <!-- <li *ngIf="getMenuAccess('40')">
                            <a routerLink="/accounting/bankDeposit">
                                <i class="metismenu-icon"></i>Bank Deposits</a>
                        </li> -->
                        <li *ngIf="getMenuAccess('40')">
                            <a routerLink="/accounting/verifypayments">
                                <i class="metismenu-icon"></i>Verify Payments</a>
                        </li>
                    </ul>
                </li>
                <li *ngIf="getModuleAccess('9')">
                    <a href="#">
                        <i class="metismenu-icon pe-7s-copy-file"></i>Report <i
                            class="metismenu-state-icon pe-7s-angle-down caret-left"></i>
                    </a>
                    <ul>
                        <li *ngIf="getMenuAccess('43')">
                            <a routerLink="/report/ward-wise-demand">
                                <i class="metismenu-icon"></i>Ward Wise Demand</a>
                        </li>
                        <li *ngIf="getMenuAccess('44')">
                            <a routerLink="/report/ward-wise-collection">
                                <i class="metismenu-icon"></i>Ward Wise Collection</a>
                        </li>
                        <li *ngIf="getMenuAccess('45')">
                            <a routerLink="/report/tax-collector-wise-collection">
                                <i class="metismenu-icon"></i>Tax Collector Wise Collection</a>
                        </li>
                        <li>
                            <a routerLink="/report/propertyWiseDemandCollection">
                                <i class="metismenu-icon"></i>Tax Collector Wise Collection</a>
                        </li>
                        <li>
                            <a routerLink="/report/propertyWiseDemandCollection">
                                <i class="metismenu-icon"></i>Property Wise DC</a>
                        </li>
                        <li>
                            <a routerLink="/report/chequeReport">
                                <i class="metismenu-icon"></i>Cheque Bounce Report</a>
                        </li>
                        <li>
                            <a routerLink="/report/counterReport">
                                <i class="metismenu-icon"></i>Counter Report</a>
                        </li>
                        <li>
                            <a routerLink="/report/zoneAndWardWise">
                                <i class="metismenu-icon"></i>Zone and Ward Wise Report</a>
                        </li>
                    </ul>
                </li>
                <li *ngIf="getModuleAccess('Masters')">
                    <a>
                        <i class="metismenu-icon pe-7s-culture"></i>{{'sbMasters' | translate}} <i
                            class="metismenu-state-icon pe-7s-angle-down caret-left"></i>
                    </a>
                    <ul>
                        <li *ngIf="getMenuAccess('8')">
                            <a routerLink="/masters/construction_type_list">
                                <i class="metismenu-icon"></i>{{'sbConstructionType' | translate}}</a>
                        </li>
                        <li *ngIf="getMenuAccess('9')">
                            <a routerLink="/masters/floor_type_list">
                                <i class="metismenu-icon"></i>{{'sbFloorType' | translate}}</a>
                        </li>
                        <li *ngIf="getMenuAccess('21')">
                            <a routerLink="/masters/honoriffic-list">
                                <i class="metismenu-icon"></i>{{'sbHonorifficType' | translate}}</a>
                        </li>
                        <li *ngIf="getMenuAccess('22')">
                            <a routerLink="/masters/muncipal-list">
                                <i class="metismenu-icon"></i>{{'sbMunicipalType' | translate}}</a>
                        </li>
                        <li *ngIf="getMenuAccess('23')">
                            <a routerLink="/masters/occupancy-list">
                                <i class="metismenu-icon"></i>{{'sbOccupancyType' | translate}}</a>
                        </li>
                        <li *ngIf="getMenuAccess('24')">
                            <a routerLink="/masters/road-list">
                                <i class="metismenu-icon"></i>{{'sbRoadType' | translate}}</a>
                        </li>
                        <li *ngIf="getMenuAccess('25')">
                            <a routerLink="/masters/usages-list">
                                <i class="metismenu-icon"></i>{{'sbUsagesType' | translate}}</a>
                        </li>
                        <li *ngIf="getMenuAccess('26')">
                            <a routerLink="/masters/user-type">
                                <i class="metismenu-icon"></i>{{'sbUserType' | translate}}</a>
                        </li>
                        <li *ngIf="getMenuAccess('28')">
                            <a routerLink="/masters/financial-year">
                                <i class="metismenu-icon"></i>{{'sbFinancialYear' | translate}}</a>
                        </li>
                        <li *ngIf="getMenuAccess('29')">
                            <a routerLink="/masters/ownership">
                                <i class="metismenu-icon"></i>{{'sbOwnership' | translate}}</a>
                        </li>
                        <li *ngIf="getMenuAccess('30')">
                            <a routerLink="/masters/vacantLandRate">
                                <i class="metismenu-icon"></i>{{'sbMasterVacantLandRate' | translate}}</a>
                        </li>
                        <li>
                            <a routerLink="/masters/document-list">
                                <i class="metismenu-icon"></i>Document List</a>
                        </li>
                        <li>
                            <a routerLink="/masters/property-document">
                                <i class="metismenu-icon"></i>Required Document</a>
                        </li>
                        <li *ngIf="getMenuAccess('14')">
                            <a routerLink="/masters/state">
                                <i class="metismenu-icon"></i>{{'sbState' | translate}}</a>
                        </li>
                        <li *ngIf="getMenuAccess('31')">
                            <a routerLink="/masters/district">
                                <i class="metismenu-icon"></i>{{'sbDistrict' | translate}}</a>
                        </li>
                        <li *ngIf="getMenuAccess('32')">
                            <a routerLink="/masters/village">
                                <i class="metismenu-icon"></i>{{'sbVillage' | translate}}</a>
                        </li>
                        <li *ngIf="getMenuAccess('33')">
                            <a routerLink="/masters/zone">
                                <i class="metismenu-icon"></i>{{'sbZone' | translate}}</a>
                        </li>
                        <li *ngIf="getMenuAccess('34')">
                            <a routerLink="/masters/ward">
                                <i class="metismenu-icon"></i>{{'sbWard' | translate}}</a>
                        </li>
                        <!-- <li>
                            <a routerLink="/masters/occupancy-type-factor">
                                <i class="metismenu-icon"></i>Occupancy Type Factor</a>
                        </li>
                        <li>
                            <a routerLink="/masters/rebate">
                                <i class="metismenu-icon"></i>Rebate</a>
                        </li>
                        <li>
                            <a routerLink="/masters/non-residential-usage-factor">
                                <i class="metismenu-icon"></i>Non Residential Usage Factor</a>
                        </li> -->
                        <!-- <li>
                            <a routerLink="/masters/penalty">
                                <i class="metismenu-icon"></i>Penalty</a>
                        </li> -->
                    </ul>
                </li>






                <li class="app-sidebar__heading" *ngIf="configuration?.waterBillMgmt=='Yes'">Water Bill Management</li>
                <li>
                    <a>
                        <i class="metismenu-icon pe-7s-config"></i>SAF Setup<i
                            class="metismenu-state-icon pe-7s-angle-down caret-left"></i>
                    </a>
                    <ul>
                        <li>
                            <a routerLink="/water-bill-management/connectiontype">
                                <i class="metismenu-icon"></i> Connection Type</a>
                        </li>
                        <li>
                            <a routerLink="/water-bill-management/rateSetup">
                                <i class="metismenu-icon"></i> Rate Setup</a>
                        </li>
                    </ul>
                </li>
                <li>
                    <a>
                        <i class="metismenu-icon pe-7s-note2"></i>SAF<i
                            class="metismenu-state-icon pe-7s-angle-down caret-left"></i>
                    </a>
                    <ul>
                        <li>
                            <a routerLink="/report-management/newwaterbill">
                                <i class="metismenu-icon"></i>New Water Bill</a>
                        </li>
                    </ul>
                </li>
                <li class="app-sidebar__heading" *ngIf="configuration?.reportMgmt=='Yes'">Reports Management</li>
                <li>
                    <a>
                        <i class="metismenu-icon pe-7s-print"></i>Reports<i
                            class="metismenu-state-icon pe-7s-angle-down caret-left"></i>
                    </a>
                    <ul>
                        <li>
                            <a routerLink="/report-management/property_report">
                                <i class="metismenu-icon"></i> Property Report</a>
                        </li>
                        <li>
                            <a routerLink="/report-management/mobiletower_report">
                                <i class="metismenu-icon"></i> Mobile Tower Report</a>
                        </li>
                        <li>
                            <a routerLink="/report-management/shop_report">
                                <i class="metismenu-icon"></i> ShopRent Report</a>
                        </li>
                        <li>
                            <a routerLink="/report-management/tradelisence_report">
                                <i class="metismenu-icon"></i> Trade License Report</a>
                        </li>
                        <li>
                            <a routerLink="/report-management/waterbilling_report">
                                <i class="metismenu-icon"></i> Water Billing Report</a>
                        </li>
                        <li     >
                            <a routerLink="/report-management/commisioner_report">
                                <i class="metismenu-icon"></i> Commisioner Report</a>
                        </li>
                        <li style="display: none;">
                            <a routerLink="/report-management/usercharge_report">
                                <i class="metismenu-icon"></i> User Charge Report</a>
                        </li>

                        <li style="display: none;">
                            <a routerLink="/report-management/lease_report">
                                <i class="metismenu-icon"></i> Lease Report</a>
                        </li>
                        <li>
                            <a routerLink="/report-management/rental_report">
                                <i class="metismenu-icon"></i> Rental Report</a>
                        </li>
                    </ul>
                </li>
                <li *ngIf="getModuleAccess('ACL')" class="app-sidebar__heading">{{'sbACLManagement' | translate}}</li>
                <li *ngIf="getModuleAccess('ACL')">
                    <a>
                        <i class="metismenu-icon pe-7s-graph2"></i>{{'sbACL' | translate}}<i
                            class="metismenu-state-icon pe-7s-angle-down caret-left"></i>
                    </a>
                    <ul>
                        <li *ngIf="getMenuAccess('10')">
                            <a routerLink="/ACL/modules">
                                <i class="metismenu-icon"></i>{{'sbModules' | translate}}</a>
                        </li>
                        <li *ngIf="getMenuAccess('11')">
                            <a routerLink="/ACL/menus">
                                <i class="metismenu-icon"></i>{{'sbMenus' | translate}}</a>
                        </li>
                        <li *ngIf="getMenuAccess('12')">
                            <a routerLink="/ACL/tabs">
                                <i class="metismenu-icon"></i>{{'sbTabs' | translate}}</a>
                        </li>
                        <li *ngIf="getMenuAccess('13')">
                            <a routerLink="/ACL/Features">
                                <i class="metismenu-icon"></i>{{'sbFeatures' | translate}}</a>
                        </li>
                    </ul>
                </li>
                <li class="app-sidebar__heading" *ngIf="configuration?.operationsMgmt=='Yes'&&configuration?.operationsMgmt!='Ramnagar'&&loggedInObj.type!='MC'&&loggedInObj.type!='ACCOUNT'">Operations</li>
                <li>
                    
                    <a href="#">
                        <i class="metismenu-icon pe-7s-user"   *ngIf="getModuleAccess('LETTERS')"></i>Letters<i
                            class="metismenu-state-icon pe-7s-angle-down caret-left"></i>
                    </a>
                    <ul>
                        <li *ngIf="getMenuAccess('UPLOAD_LETTER')">
                            <a>
                                <i class="metismenu-icon"></i>Upload Letter</a>
                        </li>
                    </ul>
                
                <ul>
                    <li *ngIf="getMenuAccess('GENERATE_LETTER')">
                        <a routerLink="/operations/letter-generate">
                            <i class="metismenu-icon"></i>Generate Letter</a>
                    </li>
                </ul>
            </li>

                <li class="app-sidebar__heading">Update</li>
                <li>

                    <ul>
                        <li>
                            <a routerLink="/update/major-list">
                                <i class="metismenu-icon"></i>Major Update</a>
                        </li>
                    </ul>
                </li>
                <li class="app-sidebar__heading">Citizen</li>
                <li>
                    <a  href="#"  *ngIf="getModuleAccess('CITIZEN')">
                        <i class="metismenu-icon pe-7s-user"></i>Citizen<i
                            class="metismenu-state-icon pe-7s-angle-down caret-left"></i>
                    </a>
                    <ul>
                        <li *ngIf="getMenuAccess('CITIZEN_CONTACT')">
                            <a routerLink="/update/contact">
                                <i class="metismenu-icon"></i>Contact</a>
                        </li>
                    </ul>
                    <ul>
                        <li *ngIf="getMenuAccess('CITIZEN_GRIEVANCE')">
                            <a routerLink="/update/grievance">
                                <i class="metismenu-icon"></i>Grievance</a>
                        </li>
                    </ul>
                </li>
                <li class="app-sidebar__heading">Admin</li>
                <li>
                    <a href="#">
                        <i class="metismenu-icon pe-7s-user"></i>Settings<i
                            class="metismenu-state-icon pe-7s-angle-down caret-left"></i>
                    </a>
                </li>
            </ul>
        </ul>
    </div>
    <div class="app-header__content headersetmobileMenu">
        <div class="app-header-right">
            <div class="page-title-actions">     
                <a class="btn-wide mb-2 mr-2 btn-icon btn-icon-right btn-shadow btn-pill btn btn-focus"
                data-toggle="modal" data-target="#logoutModal">Logout</a>
             </div>
            <!-- <button type="submit" class="btn btn-sm btn-primary">Logout</button> -->
            <div class="header-btn-lg pr-0">
                <div class="widget-content p-0">
                    <div class="widget-content-wrapper">
                        <div class="widget-content-left">
                            <div class="btn-group">
                                <!-- <a data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="p-0 btn"
                                    routerLink="/dashboard/users_profile"> -->
                                <a routerLink="/dashboard/users_profile">
                                    <img width="42" class="rounded-circle" src="../../../assets/images/user1.png"
                                        alt="">
                                    <!-- <i class="fa fa-angle-down ml-2 opacity-8"></i> -->
                                </a>
                                <div tabindex="-1" role="menu" aria-hidden="true">
                                    <!-- class="rm-pointers dropdown-menu-lg dropdown-menu dropdown-menu-right"> -->
                                    <!-- <div class="dropdown-menu-header">
                                        <div class="dropdown-menu-header-inner bg-info">
                                            <div class="menu-header-image opacity-2"
                                                style="background-image: url('assets/assets/images/dropdown-header/city3.jpg');">
                                            </div>
                                            <div class="menu-header-content text-left">
                                                <div class="widget-content p-0">
                                                    <div class="widget-content-wrapper">
                                                        <div class="widget-content-left mr-3">
                                                            <img width="42" class="rounded-circle"
                                                                src="../../../assets/images/user.jfif" alt="">
                                                        </div>
                                                        <div class="widget-content-left">
                                                            <div class="widget-heading"> {{loggedInObj.name}} </div>
                                                            Welcome {{'compB' | translate}}
                                                            <div class="widget-subheading opacity-8">Project Manager
                                                            </div>
                                                        </div>
                                                        <div class="widget-content-right mr-2">
                                                            <a (click)="logoutFun()"
                                                                class="btn-pill btn-shadow btn-shine btn btn-focus">{{'headerLogOut'
                                                                | translate}}</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> -->
                                    <div class="scroll-area-xs" style="height: 50px;">
                                        <div class="scrollbar-container ps">
                                            <!-- <ul class="nav flex-column">
                                                <li class="nav-item-header nav-item">My Account</li>
                                                <li class="nav-item">
                                                    <a routerLink="/dashboard/users_profile" class="nav-link"><i
                                                            class="fa fa-user" aria-hidden="true"></i>
                                                        &nbsp;{{'headerProfile' | translate}} </a>
                                                </li>
                                                <li class="nav-item">
                                                    <a href="javascript:void(0);" data-toggle="modal"
                                                        data-target="#exampleModal" class="nav-link"><i
                                                            class="fa fa-unlock" aria-hidden="true"></i> &nbsp;Recover
                                                        Password</a>
                                                </li>
                                            </ul> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="widget-content-left  ml-3 header-user-info">
                            <div class="widget-heading"> {{loggedInObj.name}} </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="app-sidebar sidebar-shadow" style="position: fixed;background:#fff;margin-top:60px;height:100%;">
    <div class="app-header__logo">
        <div class="logo-src"></div>
        <div class="header__pane ml-auto">
            <div>
                <button type="button" class="hamburger close-sidebar-btn hamburger--elastic"
                    data-class="closed-sidebar">
                    <span class="hamburger-box">
                        <span class="hamburger-inner"></span>
                    </span>
                </button>
            </div>
        </div>
    </div>
    <div class="scrollbar-sidebar" style="margin-top:-53px">
        <div class="app-sidebar__inner">
            <ul class="vertical-nav-menu">
                <li class="app-sidebar__heading">{{'sbAdminControl' | translate}}</li>
                <li >
                    <a> <i class="metismenu-icon pe-7s-id"></i>Dashboard<i
                        class="metismenu-state-icon pe-7s-angle-down caret-left"></i>
                </a>
                <ul>
                    <li>
                        <a routerLink="/dashboard/dashboard">
                            <i class="metismenu-icon"></i>Property Dashboard </a>
                    </li>
                    <li >
                        <a routerLink="/dashboard/mobiledashboard">
                            <i class="metismenu-icon"></i>Mobile Dashboard</a>
                    </li>
                    <li >
                        <a routerLink="/dashboard/shopdashboard">
                            <i class="metismenu-icon"></i>ShopRent Dashboard</a>
                    </li>
                    <li>
                        <a routerLink="/dashboard/tradedashboard">
                            <i class="metismenu-icon"></i>Trade-License Dashboard</a>
                    </li>
               
                  
                 
                </ul>
                </li>
                <li *ngIf="getModuleAccess('UserProfile')" class="app-sidebar__heading">{{'sbUserManagement' |
                    translate}}</li>
                <li *ngIf="getModuleAccess('UserProfile')">
                    <a> <i class="metismenu-icon pe-7s-id"></i>{{'sbUserProfile' | translate}}<i
                            class="metismenu-state-icon pe-7s-angle-down caret-left"></i>
                    </a>
                    <ul>
                        <li *ngIf="getMenuAccess('1')">
                            <a routerLink="/dashboard/Organisation">
                                <i class="metismenu-icon"></i>{{'sbOrganization' | translate}} </a>
                        </li>
                        <li *ngIf="getMenuAccess('41')">
                            <a routerLink="/user_management/user-type">
                                <i class="metismenu-icon"></i>User Type</a>
                        </li>
                        <li *ngIf="getMenuAccess('35')">
                            <a routerLink="/user_management/employee_list">
                                <i class="metismenu-icon"></i>{{'sbMunicipalUser' | translate}}</a>
                        </li>
                        <li *ngIf="getMenuAccess('36')">
                            <a routerLink="/user_management/project-manager">
                                <i class="metismenu-icon"></i>{{'sbProjectManager' | translate}}</a>
                        </li>
                        <li *ngIf="getMenuAccess('UM_Menu_ASSPM')">
                            <a routerLink="/user_management/assistant-pm">
                                <i class="metismenu-icon"></i>Assistant Project Manager
                            </a>
                        </li>
                        <li *ngIf="getMenuAccess('UM_Menu_TeamLeader')">
                            <a routerLink="/user_management/team_list">
                                <i class="metismenu-icon"></i>Team Leader</a>
                        </li>
                        <li *ngIf="getMenuAccess('UM_Menu_BO')">
                            <a routerLink="/user_management/backoffice">
                                <i class="metismenu-icon"></i>Back Office</a>
                        </li>
                        <li *ngIf="getMenuAccess('UM_Menu_BOTL')">
                            <a routerLink="/user_management/backoffice_tl">
                                <i class="metismenu-icon"></i>Back Office TL
                            </a>
                        </li>
                        <li>
                            <a routerLink="/user_management/circlemanager">
                                <i class="metismenu-icon"></i>Circle Manager</a>
                        </li>
                        <li>
                            <a routerLink="/user_management/counter">
                                <i class="metismenu-icon"></i>Counter</a>
                        </li>

                         <li *ngIf="getMenuAccess('37')">
                            <a routerLink="/user_management/tax-collector">
                                <i class="metismenu-icon"></i>{{'sbTaxCollector' | translate}}</a>
                        </li>
                        <li>
                            <a routerLink="/user_management/track-user">
                                <i class="metismenu-icon"></i>Track User</a>
                        </li>

                        <li *ngIf="getMenuAccess('42')">
                            <a routerLink="/user_management/data-entry">
                                <i class="metismenu-icon"></i>Data Entry</a>
                        </li>
                        <!-- <li *ngIf="getMenuAccess('3')">
                            <a routerLink="/user_management/reset_password">
                                <i class="metismenu-icon"></i> Reset Password </a>
                        </li> -->
                        <li>
                            <a routerLink="/user_management/identity-cards">
                                <i class="metismenu-icon"> </i>Id Cards</a>
                        </li>
                        <li>
                            <a routerLink="/user_management/mpin">
                                <i class="metismenu-icon"> </i>MPIN</a>
                        </li>
                    </ul>
                </li>
                <!-- <li class="app-sidebar__heading">Id Card MANAGEMENT</li> -->
                <!-- {{'sbSafManagement' | translate}} -->
                <!-- <li>
                    <a>
                        <i class="metismenu-icon pe-7s-id"></i>Employee ID Card<i
                            class="metismenu-state-icon pe-7s-angle-down caret-left"></i>
                    </a>
                    <ul>
                        <li>
                            <a routerLink="/user_management/identity-cards">
                                <i class="metismenu-icon"> </i>View List</a>
                        </li>
                     </ul>
                </li>     -->

                <li *ngIf="getModuleAccess('Masters') || getModuleAccess('9') || getMenuAccess('WALLET') || getModuleAccess('8') || getModuleAccess('SAFApplication') || getModuleAccess('SAF') || getModuleAccess('SAFSetup')&&configuration?.operationsMgmt!='Ramnagar'&&loggedInObj.type!='MC'&&loggedInObj.type!='ACCOUNT'" class="app-sidebar__heading">PROPERTY MANAGEMENT</li>
                <li *ngIf="getModuleAccess('SAFSetup')">
                    <a>
                        <i class="metismenu-icon pe-7s-config"></i>{{'sbSafSetup' | translate}}<i
                            class="metismenu-state-icon pe-7s-angle-down caret-left"></i>
                    </a>
                    <ul>
                        <li *ngIf="getMenuAccess('4')">
                            <a routerLink="/avr-range/avr-list">
                                <i class="metismenu-icon"> </i>{{'sbArvRange' | translate}} </a>
                        </li>
                        <!-- <li>
                            <a routerLink="/other-tax/other-tax-list">
                                <i class="metismenu-icon"></i>Other Tax </a>
                        </li> -->
                        <!-- <li>
                            <a routerLink="">
                                <i class="metismenu-icon"></i>Penalty Master </a>
                        </li> -->
                        <!-- <li>
                            <a routerLink="/construction_type_list">
                                <i class="metismenu-icon"></i>Vacant Land Rate </a>
                        </li> -->
                        <li *ngIf="getMenuAccess('5')">
                            <a routerLink="/masters/vacantLandRate">
                                <i class="metismenu-icon"></i>{{'sbVacantLandRate' | translate}}</a>
                        </li>
                        <!-- <li>
                            <a routerLink="/saf/rate-setup">
                                <i class="metismenu-icon"></i> Rate Setup </a>
                        </li> -->
                        <li *ngIf="getMenuAccess('15')">
                            <a routerLink="/saf-setup/rebate">
                                <i class="metismenu-icon"></i> {{'sbRebate' | translate}} </a>
                        </li>
                        <li *ngIf="getMenuAccess('16')">
                            <a routerLink="/masters/occupancy-type-factor">
                                <i class="metismenu-icon"></i>{{'sbOccupancyTypeFactor' | translate}}</a>
                        </li>
                        <li *ngIf="getMenuAccess('17')">
                            <a routerLink="/masters/non-residential-usage-factor">
                                <i class="metismenu-icon"></i>{{'sbNonResidentialUsageFactor' | translate}}</a>
                        </li>
                        <li *ngIf="getMenuAccess('18')">
                            <a routerLink="/masters/penalty">
                                <i class="metismenu-icon"></i>{{'sbPenalty' | translate}}</a>
                        </li>
                        <li *ngIf="getMenuAccess('19')">
                            <a routerLink="/masters/floor-ratable-area">
                                <i class="metismenu-icon"></i>{{'sbFloorRatableArea' | translate}}</a>
                        </li>
                        <li *ngIf="getMenuAccess('20')">
                            <a routerLink="/masters/property-tax">
                                <i class="metismenu-icon"></i>{{'sbPropertyTax' | translate}}</a>
                        </li>
                        <li *ngIf="getMenuAccess('27')">
                            <a routerLink="/masters/property-list">
                                <i class="metismenu-icon"></i>{{'sbPropertyType' | translate}}</a>
                        </li>
                        <li *ngIf="getMenuAccess('27')">
                            <a routerLink="/saf-setup/other-charge">
                                <i class="metismenu-icon"></i>{{'sbOtherCharge' | translate}}</a>
                        </li>
                    </ul>
                </li>
                <li *ngIf="getModuleAccess('SAF')">
                    <a href="#">
                        <i class="metismenu-icon pe-7s-note2"></i> {{'sbSAF' | translate}} <i
                            class="metismenu-state-icon pe-7s-angle-down caret-left"></i>
                    </a>
                    <ul>
                        <li *ngIf="getMenuAccess('6')">
                            <a routerLink="/saf/saf-calculations">
                                <i class="metismenu-icon"> </i>{{'sbNewAssesment' | translate}}</a>
                            <!-- <a routerLink="/saf/reassessment">
                                <i class="metismenu-icon"> </i>Reassessment</a> -->
                                <a routerLink="/saf/Estimated">
                                    <i class="metismenu-icon"> </i>Estimated Property Tax</a>
                        </li>
                        <!-- <li>
                            <a routerLink="">
                                <i class="metismenu-icon"></i>Reassesment </a>
                        </li>
                        <li>
                            <a>
                                <i class="metismenu-icon"> </i>SAF Calculations</a>
                        </li> -->
                    </ul>
                </li>
                <li *ngIf="getModuleAccess('SAFApplication')">
                    <a href="#">
                        <i class="metismenu-icon pe-7s-plugin"></i>{{'sbSAFApplication' | translate}} <i
                            class="metismenu-state-icon pe-7s-angle-down caret-left"></i>
                    </a>
                    <ul>
                        <li *ngIf="getMenuAccess('7')">
                            <a routerLink="/saf/property-lists">
                                <i class="metismenu-icon"></i>{{'sbPropertyList' | translate}}</a>
                        </li>
                        <li>
                            <a routerLink="/saf/locate_property">
                                <i class="metismenu-icon"></i>Locate Property</a>
                        </li>
                        <li *ngIf="getMenuAccess('38')">
                            <a routerLink="/saf/payments">
                                <i class="metismenu-icon"></i>{{'sbCollections' | translate}}</a>
                        </li>
                        <li *ngIf="getMenuAccess('39')">
                            <a routerLink="/saf/demands">
                                <i class="metismenu-icon"></i>{{'sbDemands' | translate}}</a>
                        </li>
                        <li *ngIf="getMenuAccess('47')">
                            <a routerLink="/saf/edit-property-request">
                                <i class="metismenu-icon"></i>Edit Property Request</a>
                        </li>
                        <li *ngIf="getMenuAccess('PROPERTY_DELETEREQUEST')">
                            <a routerLink="/saf/delete-request">
                                <i class="metismenu-icon"></i>Delete Request</a>
                        </li>
                        <li *ngIf="getMenuAccess('47')">
                            <a routerLink="/saf/reassessment-list">
                                <i class="metismenu-icon"></i>Reassessment List</a>
                        </li>
                        <li *ngIf="getMenuAccess('47')">
                            <a routerLink="/saf/mutation-list">
                                <i class="metismenu-icon"></i>Mutation List</a>
                        </li>
                        <li  *ngIf="getMenuAccess('LEGACY_LIST')">
                            <a routerLink="/saf/legacylist">
                                <i class="metismenu-icon"></i>Legacy List</a>
                        </li>
                       
                        <li>
                            <a routerLink="/saf/fixedarv-list">
                                <i class="metismenu-icon"></i>Fixed ARV List</a>
                        </li>
                        <li  *ngIf="getMenuAccess('REJECTED_PAYMENT_LIST')">
                            <a routerLink="/saf/rejectedlist">
                                <i class="metismenu-icon"></i>Rejected Payment</a>
                        </li>
                        <li *ngIf="getMenuAccess('47')">
                            <a routerLink="/saf/payment-list">
                                <i class="metismenu-icon"></i>Update Payment Mode</a>
                        </li>
                        <!-- <li>
                            <a routerLink="/saf/search_application">
                                <i class="metismenu-icon"></i> Search Application </a>
                        </li> -->
                        <li *ngIf="getMenuAccess('46')">
                            <a routerLink="/saf/survey_and_tax">
                                <i class="metismenu-icon"></i>Survey And Tax</a>
                        </li>
                    </ul>
                </li>
                <li>

                </li>
                <li *ngIf="getModuleAccess('8')">
                    <a href="#">
                        <i class="metismenu-icon pe-7s-user"></i>Accounting <i
                            class="metismenu-state-icon pe-7s-angle-down caret-left"></i>
                    </a>
                    <ul>
                        <!-- <li *ngIf="getMenuAccess('40')">
                            <a routerLink="/accounting/bankDeposit">
                                <i class="metismenu-icon"></i>Bank Deposits</a>
                        </li> -->
                        <li *ngIf="getMenuAccess('40')">
                            <a routerLink="/accounting/verifypayments">
                                <i class="metismenu-icon"></i>Verify Payments</a>
                        </li>
                        <!-- <li>
                            <a routerLink="/accounting/user-verify-payments">
                                <i class="metismenu-icon"></i>Verify Payments V2</a>
                        </li> -->
                    </ul>
                </li>

                <li *ngIf="getMenuAccess('WALLET')">
                    <a href="#">
                        <i class="metismenu-icon pe-7s-wallet"></i>Wallet<i
                            class="metismenu-state-icon pe-7s-angle-down caret-left"></i>
                    </a>
                    <ul>
                        <li>
                            <a routerLink="/wallet/wallet">
                                <i class="metismenu-icon"></i>Wallet</a>
                        </li>
                    </ul>
                </li>



                <li *ngIf="getModuleAccess('9')">
                    <a href="#">
                        <i class="metismenu-icon pe-7s-copy-file"></i>Report <i
                            class="metismenu-state-icon pe-7s-angle-down caret-left"></i>
                    </a>
                    <ul>
                        <li *ngIf="getMenuAccess('43')">
                            <a routerLink="/report/ward-wise-demand">
                                <i class="metismenu-icon"></i>Ward Wise Demand </a>
                        </li>
                        <li *ngIf="getMenuAccess('44')">
                            <a routerLink="/report/ward-wise-collection">
                                <i class="metismenu-icon"></i>Ward Wise Collection</a>
                        </li>
                        <li *ngIf="getMenuAccess('45')">
                            <a routerLink="/report/tax-collector-wise-collection">
                                <i class="metismenu-icon"></i>Team Wise Collection</a>
                        </li>
                        <li *ngIf="getMenuAccess('REPORT_PROPERTY_PWDC')">
                            <a routerLink="/report/propertyWiseDemandCollection">
                                <i class="metismenu-icon"></i>Property Wise DC</a>
                        </li>
                        <li *ngIf="getMenuAccess('REPORT_PROPERTY_CBR')">
                            <a routerLink="/report/chequeReport">
                                <i class="metismenu-icon"></i>Cheque Bounce Report</a>
                        </li>
                        <li *ngIf="getMenuAccess('REPORT_PROPERTY_CR')">
                            <a routerLink="/report/counterReport">
                                <i class="metismenu-icon"></i>Counter Report</a>
                        </li>
                        <li *ngIf="getMenuAccess('REPORT_PROPERTY_ZW')">
                            <a routerLink="/report/zoneAndWardWise">
                                <i class="metismenu-icon"></i>Zone and Ward Wise Report</a>
                        </li>
                    </ul>
                </li>
                <li *ngIf="getModuleAccess('Masters')">
                    <a>
                        <i class="metismenu-icon pe-7s-culture"></i>{{'sbMasters' | translate}} <i
                            class="metismenu-state-icon pe-7s-angle-down caret-left"></i>
                    </a>
                    <ul>
                        <li *ngIf="getMenuAccess('8')">
                            <a routerLink="/masters/construction_type_list">
                                <i class="metismenu-icon"></i>{{'sbConstructionType' | translate}}</a>
                        </li>
                        <li *ngIf="getMenuAccess('9')">
                            <a routerLink="/masters/floor_type_list">
                                <i class="metismenu-icon"></i>{{'sbFloorType' | translate}}</a>
                        </li>
                        <li *ngIf="getMenuAccess('21')">
                            <a routerLink="/masters/honoriffic-list">
                                <i class="metismenu-icon"></i>{{'sbHonorifficType' | translate}}</a>
                        </li>
                        <li *ngIf="getMenuAccess('22')">
                            <a routerLink="/masters/muncipal-list">
                                <i class="metismenu-icon"></i>{{'sbMunicipalType' | translate}}</a>
                        </li>
                        <li *ngIf="getMenuAccess('23')">
                            <a routerLink="/masters/occupancy-list">
                                <i class="metismenu-icon"></i>{{'sbOccupancyType' | translate}}</a>
                        </li>
                        <li *ngIf="getMenuAccess('24')">
                            <a routerLink="/masters/road-list">
                                <i class="metismenu-icon"></i>{{'sbRoadType' | translate}}</a>
                        </li>
                        <li *ngIf="getMenuAccess('25')">
                            <a routerLink="/masters/usages-list">
                                <i class="metismenu-icon"></i>{{'sbUsagesType' | translate}}</a>
                        </li>
                        <li *ngIf="getMenuAccess('26')">
                            <a routerLink="/masters/user-type">
                                <i class="metismenu-icon"></i>{{'sbUserType' | translate}}</a>
                        </li>
                        <li *ngIf="getMenuAccess('28')">
                            <a routerLink="/masters/financial-year">
                                <i class="metismenu-icon"></i>{{'sbFinancialYear' | translate}}</a>
                        </li>
                        <li *ngIf="getMenuAccess('29')">
                            <a routerLink="/masters/ownership">
                                <i class="metismenu-icon"></i>{{'sbOwnership' | translate}}</a>
                        </li>
                        <li *ngIf="getMenuAccess('30')">
                            <a routerLink="/masters/vacantLandRate">
                                <i class="metismenu-icon"></i>{{'sbMasterVacantLandRate' | translate}}</a>
                        </li>
                        <li>
                            <a routerLink="/masters/document-list">
                                <i class="metismenu-icon"></i>Document List</a>
                        </li>
                        <li>
                            <a routerLink="/masters/required-document">
                                <i class="metismenu-icon"></i>Required Document</a>
                        </li>
                        <li *ngIf="getMenuAccess('14')">
                            <a routerLink="/masters/state">
                                <i class="metismenu-icon"></i>{{'sbState' | translate}}</a>
                        </li>
                        <li *ngIf="getMenuAccess('31')">
                            <a routerLink="/masters/district">
                                <i class="metismenu-icon"></i>{{'sbDistrict' | translate}}</a>
                        </li>
                        <li *ngIf="getMenuAccess('32')">
                            <a routerLink="/masters/village">
                                <i class="metismenu-icon"></i>{{'sbVillage' | translate}}</a>
                        </li>
                        <li *ngIf="getMenuAccess('33')">
                            <a routerLink="/masters/zone">
                                <i class="metismenu-icon"></i>{{'sbZone' | translate}}</a>
                        </li>
                        <li *ngIf="getMenuAccess('34')">
                            <a routerLink="/masters/ward">
                                <i class="metismenu-icon"></i>{{'sbWard' | translate}}</a>
                        </li>
                        <!-- <li>
                            <a routerLink="/masters/occupancy-type-factor">
                                <i class="metismenu-icon"></i>Occupancy Type Factor</a>
                        </li>
                        <li>
                            <a routerLink="/masters/rebate">
                                <i class="metismenu-icon"></i>Rebate</a>
                        </li>
                        <li>
                            <a routerLink="/masters/non-residential-usage-factor">
                                <i class="metismenu-icon"></i>Non Residential Usage Factor</a>
                        </li> -->
                        <!-- <li>
                            <a routerLink="/masters/penalty">
                                <i class="metismenu-icon"></i>Penalty</a>
                        </li> -->
                    </ul>
                </li>
                <li *ngIf="getMenuAccess('BIRTHSAF') || getMenuAccess('BIRTH_SAF_SETUP')" class="app-sidebar__heading"> Birth certificate Management
                </li>
                <li *ngIf="getMenuAccess('BIRTH_SAF_SETUP')">
                    <a href="#">
                        <i class="metismenu-icon pe-7s-user"></i>SAF Setup<i
                            class="metismenu-state-icon pe-7s-angle-down caret-left"></i>
                    </a>
                    <ul>
                        <li>
                            <a routerLink="/BirthCertificate/Hospitalmaster">
                                <i class="metismenu-icon"></i>Hospital Master</a>
                        </li>
                    </ul>
                </li>
                  <li *ngIf="getMenuAccess('BIRTHSAF')">
                    <a href="#">
                        <i class="metismenu-icon pe-7s-note2"></i>SAF<i
                            class="metismenu-state-icon pe-7s-angle-down caret-left"></i>
                    </a>
                    <ul>
                        <li>
                            <a routerLink="/BirthCertificate/Newregistration">
                                <i class="metismenu-icon"></i>New Registration</a>
                        </li>
                        <li>
                            <a routerLink="/BirthCertificate/Searchbirthcertificate">
                                <i class="metismenu-icon"></i>Search Birth Certificate</a>
                        </li>
                    </ul>
                </li>

                  <li *ngIf="getMenuAccess('SW_SAF') || getMenuAccess('SW_SAF_SETUP')" class="app-sidebar__heading"> Solid Waste Charge Management
                </li>
                <li *ngIf="getMenuAccess('SW_SAF_SETUP')"> 
                    <a href="#">
                        <i class="metismenu-icon pe-7s-user"></i>SAF Setup<i
                            class="metismenu-state-icon pe-7s-angle-down caret-left"></i>
                    </a>
                    <ul>
                        <li >
                            <a routerLink="/solid-waste/category">
                                <i class="metismenu-icon" ></i>Category</a>
                        </li>
                        <li>
                            <a  routerLink="/solid-waste/subcategory">
                                <i class="metismenu-icon"></i>Sub Category</a>
                        </li>
                        <li>
                            <a routerLink="/solid-waste/ratemaster">
                                <i class="metismenu-icon" ></i>Rate Master</a>
                        </li>

                    </ul>
                </li>
                  <li *ngIf="getMenuAccess('SW_SAF')">
                    <a href="#">
                        <i class="metismenu-icon pe-7s-note2"></i>SAF<i
                            class="metismenu-state-icon pe-7s-angle-down caret-left"></i>
                    </a>
                    <ul>
                        <li>
                            <a routerLink="/solid-waste/newassessment">
                                <i class="metismenu-icon"></i>New Assessment</a>
                        </li>
                        <li>
                            <a routerLink="/solid-waste/search">
                                <i class="metismenu-icon"></i>Search</a>
                        </li>
                        <li>
                            <a routerLink="/solid-waste/collections">
                                <i class="metismenu-icon"></i>Collections</a>
                        </li>
                        <li>
                            <a routerLink="/solid-waste/reassessmentlist">
                                <i class="metismenu-icon"></i>Reassessment </a>
                        </li>
              
                    </ul>
                </li>
                <li class="app-sidebar__heading" *ngIf="configuration?.isMobileTower=='Yes'&&configuration?.operationsMgmt!='Ramnagar'&&loggedInObj.type!='MC'&&loggedInObj.type!='ACCOUNT'"> Mobile Tower Management
                </li>
                <li *ngIf="configuration?.isMobileTower=='Yes'">
                    <a href="#" *ngIf="getModuleAccess('MT_MODULE_SAF_SETUP')">
                        <i class="metismenu-icon pe-7s-user"></i>SAF Setup<i
                            class="metismenu-state-icon pe-7s-angle-down caret-left"></i>
                    </a>
                    <ul>
                        <li *ngIf="getMenuAccess('MT_MENU_MTREGTAX')">
                            <a routerLink="/mobile-tower/mobile-tower-tax">
                                <i class="metismenu-icon"></i>Mobile Tower Tax</a>
                        </li>

                    </ul>
                </li>
                <li *ngIf="configuration?.isMobileTower=='Yes'">
                    <a href="#" *ngIf="getModuleAccess('MT_MODULE_SAF')">
                        <i class="metismenu-icon pe-7s-note2"></i>SAF<i
                            class="metismenu-state-icon pe-7s-angle-down caret-left"></i>
                    </a>
                    <ul>
                        <li *ngIf="getMenuAccess('MT_MENU_NEW')">
                            <a routerLink="/mobile-tower/newmobile">
                                <i class="metismenu-icon"></i>New Mobile Tower </a>
                        </li>
                        <li *ngIf="getMenuAccess('MT_MENU_SEARCH')">
                            <a routerLink="/mobile-tower/mobile-tower-saf">
                                <i class="metismenu-icon"></i>Search Mobile Tower</a>
                        </li>
                        <li *ngIf="getMenuAccess('MT_MENU_REGCOLLECTION')">
                            <a routerLink="/mobile-tower/collection">
                                <i class="metismenu-icon"></i>Registration Collection </a>
                        </li>
                        <li *ngIf="getMenuAccess('MT_MENU_LEGCOLLECTION')">
                            <a routerLink="/mobile-tower/legacycollection">
                                <i class="metismenu-icon"></i>Legacy Collection</a>
                        </li>
                        <li *ngIf="getMenuAccess('MT_MENU_YEARLYCOLLECTION')">
                            <a routerLink="/mobile-tower/yearlycollection">
                                <i class="metismenu-icon"></i>Yearly Collection</a>
                        </li>
                        <li *ngIf="getMenuAccess('PROPERTY_DELETEREQUEST')">
                            <a routerLink="/mobile-tower/delete-request">
                                <i class="metismenu-icon"></i>Delete Request</a>
                        </li>
                        <li *ngIf="getMenuAccess('MT_MENU_BEMTREQ')">
                            <a routerLink="/mobile-tower/editproperty">
                                <i class="metismenu-icon"></i>Edit Mobile Reassesment</a>
                        </li>

                    </ul>
                </li>

                <li class="app-sidebar__heading" *ngIf="configuration?.tradeLisenceMgmt=='Yes'&&configuration?.operationsMgmt!='Ramnagar'&&loggedInObj.type!='MC'&&loggedInObj.type!='ACCOUNT'">Trade License Management
                </li>
                <li *ngIf="getModuleAccess('TL_MODULE_SAFSETUP')">
                    <a href="#">
                        <i class="metismenu-icon pe-7s-config"></i>SAF Setup<i
                            class="metismenu-state-icon pe-7s-angle-down caret-left"></i>
                    </a>
                    <ul>
                        <li *ngIf="getMenuAccess('TL_MENU_BUSINESSTYPE')">
                        <a routerLink="/trade-licence/business-type">
                                <i class="metismenu-icon"></i> Business Type</a>
                        </li>
                        <li *ngIf="getMenuAccess('TL_MENU_CATEGORYTYPE')">
                            <a routerLink="/trade-licence/category-type">
                                <i class="metismenu-icon"></i> Category Type</a>
                        </li>
                        <li *ngIf="getMenuAccess('TL_MENU_RATEMASTER')">
                            <a routerLink="/trade-licence/rate-master">
                                <i class="metismenu-icon"></i> Rate Master</a>
                        </li>
                    </ul>
                </li>
                <li *ngIf="getModuleAccess('TL_MODULE_SAF')">
                    <a href="#">
                        <i class="metismenu-icon pe-7s-note2"></i>SAF<i
                            class="metismenu-state-icon pe-7s-angle-down caret-left"></i>
                    </a>
                    <ul>
                        <li *ngIf="getMenuAccess('TL_MENU_NEWTL')">
                            <a routerLink="/trade-licence/new-trade-licence">
                                <i class="metismenu-icon"></i>New Trade Licence</a>
                        </li>
                        <li *ngIf="getMenuAccess('TL_MENU_SEARCHTL')">
                            <a routerLink="/trade-licence/search-trade-licence">
                                <i class="metismenu-icon"></i>Search Trade Licence</a>
                        </li>
                        <li *ngIf="getMenuAccess('TL_MENU_COLLECTIONSTL')">
                            <a routerLink="/trade-licence/collection" *ngIf="collectionconfig">
                                <i class="metismenu-icon"></i>Collections</a>
                        </li>
                        <li *ngIf="collectionconfig1">
                            <a routerLink="/trade-licence/collection1">
                                <i class="metismenu-icon"></i>Collections-V2</a>
                        </li>
                        <li *ngIf="getMenuAccess('PROPERTY_DELETEREQUEST')">
                            <a routerLink="/trade-licence/delete-request">
                                <i class="metismenu-icon"></i>Delete Request</a>
                        </li>
                        <li>
                            <a routerLink="/trade-licence/editproperty">
                                <i class="metismenu-icon"></i>Edit Trade Reassesment</a>
                        </li>
                        <!-- <li>
                            <a routerLink="/trade-licence/demand">
                                <i class="metismenu-icon"></i>Demands</a>
                        </li> -->
                    </ul>
                </li>

                <li class="app-sidebar__heading" *ngIf="configuration?.shopRentMgmt=='Yes'&&configuration?.operationsMgmt!='Ramnagar'&&loggedInObj.type!='MC'&&loggedInObj.type!='ACCOUNT'">Shop Rent Management</li>
                <li *ngIf="configuration?.shopRentMgmt=='Yes'">
                    <a href="#" *ngIf="getModuleAccess('SR_MODULE_SAF_SETUP')">
                        <i class="metismenu-icon pe-7s-config"></i>SAF Setup<i
                            class="metismenu-state-icon pe-7s-angle-down caret-left"></i>
                    </a>
                    <ul>
                        <li *ngIf="getMenuAccess('SR_MENU_CATEGORRY')">
                            <a routerLink="/shop-rent/category">
                                <i class="metismenu-icon"></i> Category</a>
                        </li>
                        <li *ngIf="getMenuAccess('SR_MENU_SUCATEGORRY')">
                            <a routerLink="/shop-rent/sub-category">
                                <i class="metismenu-icon"></i> Sub Category</a>
                        </li>
                        <li *ngIf="getMenuAccess('SR_MENU_RATEMASTER')">
                            <a routerLink="/shop-rent/rate-master">
                                <i class="metismenu-icon"></i> Rate Master</a>
                        </li>
                    </ul>
                </li>
                <li *ngIf="configuration?.shopRentMgmt=='Yes'">
                    <a href="#" *ngIf="getModuleAccess('SR_MODULE_SAF')">
                        <i class="metismenu-icon pe-7s-note2"></i>SAF<i
                            class="metismenu-state-icon pe-7s-angle-down caret-left"></i>
                    </a>
                    <ul>
                        <li *ngIf="getMenuAccess('SR_MENU_NEWSHOP')">
                            <a routerLink="/shop-rent/new-shop">
                                <i class="metismenu-icon"></i>New Shop</a>
                        </li>
                        <li *ngIf="getMenuAccess('SR_MENU_SEARCHSHOP')">
                            <a routerLink="/shop-rent/search-shop">
                                <i class="metismenu-icon"></i>Search Shop</a>
                        </li>
                        <li *ngIf="getMenuAccess('SR_MENU_COLLECTION')">
                            <a routerLink="/shop-rent/collection">
                                <i class="metismenu-icon"></i>Collection</a>
                        </li>
                        <li *ngIf="getMenuAccess('SR_MENU_COLLECTION')">
                            <a routerLink="/shop-rent/legacycollection">
                                <i class="metismenu-icon"></i>Legacy Collection</a>
                        </li>
                        <li *ngIf="getMenuAccess('SR_MENU_DEMAND')">
                            <a routerLink="/shop-rent/demand">
                                <i class="metismenu-icon"></i>Demand</a>
                        </li>
                        <li *ngIf="getMenuAccess('PROPERTY_DELETEREQUEST')">
                            <a routerLink="/shop-rent/delete-request">
                                <i class="metismenu-icon"></i>Delete Request</a>
                        </li>
                        <li *ngIf="getMenuAccess('SAF_Reassesmentlist')">
                            <a routerLink="/shop-rent/shop-reassmentlist">
                                <i class="metismenu-icon"></i>Reassesment list</a>
                        </li>
                    </ul>
                </li>

                <!-- <li class="app-sidebar__heading" >Water Charge Management</li>
                <li>
                    <a  >
                        <i class="metismenu-icon pe-7s-note2"></i>SAF Setup<i
                            class="metismenu-state-icon pe-7s-angle-down caret-left"></i>
                    </a>
                    <ul>
                        <li >
                            <a routerLink="/waterchargemgmt/arv">
                                <i class="metismenu-icon"></i>ARV</a>
                        </li>
                        <li>
                            <a routerLink="/waterchargemgmt/Connectiontype">
                                <i class="metismenu-icon"></i>Connection Type</a>
                        </li>
                    </ul>
                </li> -->

                <li *ngIf="getMenuAccess('WB_SAF') || getMenuAccess('WB_SAF_SETUP')"  class="app-sidebar__heading" >Water Bill Management</li>
                <li  *ngIf="getMenuAccess('WB_SAF_SETUP')">
                    <a>
                        <i class="metismenu-icon pe-7s-config"></i>SAF Setup<i
                            class="metismenu-state-icon pe-7s-angle-down caret-left"></i>
                    </a>
                    <ul>
                        <li *ngIf="getMenuAccess('SAFSetup_ConnectionType')"> 
                            <a routerLink="/water-bill-management/connectiontype">
                                <i class="metismenu-icon"></i> Connection Type</a>
                        </li>
                        <li *ngIf="getMenuAccess('SAFSetup_Ratesetup')">
                            <a routerLink="/water-bill-management/rateSetup">
                                <i class="metismenu-icon"></i> Rate Setup</a>
                        </li>
                    </ul>

                </li>
                <li *ngIf="getMenuAccess('WB_SAF')">
                    <a>
                        <i class="metismenu-icon pe-7s-note2"></i>SAF<i
                            class="metismenu-state-icon pe-7s-angle-down caret-left"></i>
                    </a>
                    <ul>
                        <li *ngIf="getMenuAccess('SAF_NewWaterBill')">
                            <a routerLink="/water-bill-management/newwaterbill">
                                <i class="metismenu-icon"></i>New Water Bill</a>
                        </li>
                    </ul>
                </li>

                
                <li *ngIf="configuration?.accountMgmt=='Yes'" class="app-sidebar__heading">Accounting Management</li>
                <li >
                    <a *ngIf="getModuleAccess('AM_ACCOUNTS')">
                        <i class="metismenu-icon pe-7s-print"></i>Accounts<i
                            class="metismenu-state-icon pe-7s-angle-down caret-left"></i>
                    </a>
                    <ul>
                        <li *ngIf="getMenuAccess('ACCOUNT_PROPERTY')">
                            <a routerLink="/accounting/property">
                                <i class="metismenu-icon"></i> Property</a>
                        </li>
                        <li *ngIf="getMenuAccess('ACCOUNT_PROPERTY_COLLECTEDPAYMENTS')">
                            <a routerLink="/accounting/property-colectedPayments">
                                <i class="metismenu-icon"></i> Property-CollectedPayments</a>
                        </li>
                        <li *ngIf="getMenuAccess('ACCOUNT_VERIFYPAYMENTS')">
                            <a routerLink="/accounting/user-verify-payments">
                                <i class="metismenu-icon"></i>Verify Payments V2</a>
                        </li>
                        <li>
                            <a routerLink="/accounting/module-operator">
                                <i class="metismenu-icon"></i>Module Operator</a>
                        </li>
                        <!-- <li *ngIf="getMenuAccess('ACCOUNT_COLLECTEDVERIFYPAYMENTS')"> -->
                        <li *ngIf="getMenuAccess('ACCOUNT_COLLECTEDVERIFYPAYMENTS')">
                            <a routerLink="/accounting/collected-payments">
                                <i class="metismenu-icon"></i>Collected Verify Payments V2</a>
                        </li>
                       
                       
                    </ul>
                </li>

                <li class="app-sidebar__heading" *ngIf="getMenuAccess('REPORT_SR') || getMenuAccess('REPORT_TL')   || getMenuAccess('REPORT_WB') || getMenuAccess('REPORT_RENT') || (getMenuAccess('REPORT_MT') || getMenuAccess('REPORT_PROPERTY') || getModuleAccess('REPORT')) && configuration?.reportMgmt=='Yes'">Reports Management</li>
                <li *ngIf="configuration?.reportMgmt=='Yes'">
                    <a *ngIf="getModuleAccess('REPORT')">
                        <i class="metismenu-icon pe-7s-print"></i>Reports<i
                            class="metismenu-state-icon pe-7s-angle-down caret-left"></i>
                    </a>
                    <ul>
                        <li *ngIf="getMenuAccess('REPORT_PROPERTY')">
                            <a routerLink="/report-management/property_report">
                                <i class="metismenu-icon"></i> Property Report</a>
                        </li>
                        <li *ngIf="getMenuAccess('REPORT_MT')">
                            <a routerLink="/report-management/mobiletower_report">
                                <i class="metismenu-icon"></i> Mobile Tower Report</a>
                        </li>
                        <li *ngIf="getMenuAccess('REPORT_SR')">
                            <a routerLink="/report-management/shop_report">
                                <i class="metismenu-icon"></i> ShopRent Report</a>
                        </li>
                        <li *ngIf="getMenuAccess('REPORT_TL')">
                            <a routerLink="/report-management/tradelisence_report">
                                <i class="metismenu-icon"></i> Trade License Report</a>
                        </li>
                        <li *ngIf="getMenuAccess('REPORT_WB')">
                            <a routerLink="/report-management/waterbilling_report">
                                <i class="metismenu-icon"></i> Water Billing Report</a>
                        </li>
                          <li *ngIf="getMenuAccess('REPORT_COMMISSIONER')">
                            <a routerLink="/report-management/commisioner_report">
                                <i class="metismenu-icon"></i>Report Details</a>
                        </li>
                        <li style="display: none;" *ngIf="getMenuAccess('REPORT_UC')">
                            <a routerLink="/report-management/usercharge_report">
                                <i class="metismenu-icon"></i> User Charge Report</a>
                        </li>

                        <li style="display: none;" *ngIf="getMenuAccess('REPORT_LEASE')">
                            <a routerLink="/report-management/lease_report">
                                <i class="metismenu-icon"></i> Lease Report</a>
                        </li>
                        <li *ngIf="getMenuAccess('REPORT_RENT')">
                            <a routerLink="/report-management/rental_report">
                                <i class="metismenu-icon"></i> Rental Report</a>
                        </li>
                    </ul>
                </li>

                <li *ngIf="getModuleAccess('ACL')" class="app-sidebar__heading">{{'sbACLManagement' | translate}}</li>
                <li *ngIf="getModuleAccess('ACL')">
                    <a>
                        <i class="metismenu-icon pe-7s-graph2"></i>{{'sbACL' | translate}}<i
                            class="metismenu-state-icon pe-7s-angle-down caret-left"></i>
                    </a>
                    <ul>
                        <li *ngIf="getMenuAccess('10')">
                            <a routerLink="/ACL/modules">
                                <i class="metismenu-icon"></i>{{'sbModules' | translate}}</a>
                        </li>
                        <li *ngIf="getMenuAccess('11')">
                            <a routerLink="/ACL/menus">
                                <i class="metismenu-icon"></i>{{'sbMenus' | translate}}</a>
                        </li>
                        <li *ngIf="getMenuAccess('12')">
                            <a routerLink="/ACL/tabs">
                                <i class="metismenu-icon"></i>{{'sbTabs' | translate}}</a>
                        </li>
                        <li *ngIf="getMenuAccess('13')">
                            <a routerLink="/ACL/Features">
                                <i class="metismenu-icon"></i>{{'sbFeatures' | translate}}</a>
                        </li>
                    </ul>
                </li>
                <li class="app-sidebar__heading" *ngIf="configuration?.operationsMgmt=='Yes'&&configuration?.operationsMgmt!='Ramnagar'&&loggedInObj.type!='MC'&&loggedInObj.type!='ACCOUNT'">Operations</li>
                <li>
                    <ul>
                        <li *ngIf="getModuleAccess('LETTERS')">
                        <a> 
                            <i class="metismenu-icon pe-7s-user"   ></i>Letters<i
                                class="metismenu-state-icon pe-7s-angle-down caret-left"></i>
                        </a>
                        </li>
                    </ul>
                        <ul>
                            <li *ngIf="getMenuAccess('UPLOAD_LETTER')">
                                <a>
                                    <i class="metismenu-icon"></i>Upload Letter</a>
                            </li>
                        </ul>
                    
                    <ul>
                        <li *ngIf="getMenuAccess('GENERATE_LETTER')">
                            <a routerLink="/operations/letter-generate">
                                <i class="metismenu-icon"></i>Generate Letter</a>
                        </li>
                    </ul>
                </li>
                <li>
                    <a *ngIf="getMenuAccess('OP_CITIZEN')" href="#">
                        <i class="metismenu-icon pe-7s-user" ></i>Update<i
                            class="metismenu-state-icon pe-7s-angle-down caret-left"></i>
                    </a>
                    <ul>
                        <li *ngIf="getMenuAccess('Update_Major-Update')">
                            <a routerLink="/update/major-list">
                                <i class="metismenu-icon"></i>Major-Update</a>
                        </li>
                    </ul>

                </li>
                <li>
                    <a  href="#"  *ngIf="getModuleAccess('CITIZEN')">
                        <i class="metismenu-icon pe-7s-user"></i>Citizen<i
                            class="metismenu-state-icon pe-7s-angle-down caret-left"></i>
                    </a>
                    <ul>
                        <li *ngIf="getMenuAccess('CITIZEN_CONTACT')">
                            <a routerLink="/update/contact">
                                <i class="metismenu-icon"></i>Contact</a>
                        </li>
                    </ul>
                    <ul>
                        <li *ngIf="getMenuAccess('CITIZEN_GRIEVANCE')">
                            <a routerLink="/update/grievance">
                                <i class="metismenu-icon"></i>Grievance</a>
                        </li>
                    </ul>
                </li>
                <!-- <li *ngIf="getMenuAccess('ADMIN_SETTINGS')" class="app-sidebar__heading">Admin</li> -->
                <li class="app-sidebar__heading" *ngIf="getModuleAccess('ADMIN_SETTINGS')">Admin</li>
                <!-- <li *ngIf="getMenuAccess('ADMIN_SETTINGS')"> -->
                <li >
                    <a href="#">
                        <i class="metismenu-icon pe-7s-user"></i>Settings<i
                            class="metismenu-state-icon pe-7s-angle-down caret-left"></i>
                    </a>
                    <ul>
                <!-- <li *ngIf="getMenuAccess('Settings_ProductSettings')"> -->
                <li>
                    <a routerLink="/admin/settings">
                        <i class="metismenu-icon"></i>Product Settings</a>
                </li>
                <li>
                    <a routerLink="/admin/hdfc">
                        <i class="metismenu-icon"></i>HDFC Payment</a>
                </li>
                </ul>
                    
                </li>
                

            </ul>
        </div>
    </div>
</div>
<!-- Modal -->
<!-- <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content" style="margin-top: 180px;">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Change Passowrd</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <form [formGroup]="passwordForm" (ngSubmit)="changePassword()">
                <div class="modal-body">
                    <input type="password" class="form-control" formControlName="oldpassword"
                        [ngClass]="{'red-border-class': regisSubmitted && fr.oldpassword.errors}"
                        placeholder="Enter Old Password"><br>
                    <input type="password" class="form-control" formControlName="password"
                        [ngClass]="{'red-border-class': regisSubmitted && fr.password.errors}"
                        placeholder="Enter New Password"><br>
                    <input type="password" class="form-control" formControlName="cPassword"
                        [ngClass]="{'red-border-class': regisSubmitted && fr.cPassword.errors}"
                        placeholder="Confirm New Password">
                    <p *ngIf="errText!=''" class="red">{{errText}}</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                    <button type="submit" class="btn btn-primary">Save changes</button>
                </div>
            </form>
        </div>
    </div>
</div> -->
<div class="modal fade" id="logoutModal" style="margin-top: 50px;" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            
          <p style="padding: 20px;">Do You Want To Logout?</p>
         
            <div class="modal-footer">
                <button type="button" class="btn btn-Danger"  data-dismiss="modal">{{'propertyLSubMdlBtnClose' |
                    translate}}</button>
                <button type="button" class="btn btn-secondary" (click)="logoutFun()" data-dismiss="modal">Logout</button>
            </div>
        </div>
    </div>
</div>