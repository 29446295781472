import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth.guard';
import {AdminModule} from './admin/admin.module'


const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'user_management',
    loadChildren: () => import('./usermanagement/usermanagement.module').then(m => m.UsermanagementModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'saf',
    loadChildren: () => import('./saf/saf.module').then(m => m.SafModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'avr-range',
    loadChildren: () => import('./avr-range/avr-range.module').then(m => m.AvrRangeModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'other-tax',
    loadChildren: () => import('./other-tax/other-tax.module').then(m => m.OtherTaxModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'masters',
    loadChildren: () => import('./masters/masters.module').then(m => m.MastersModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'saf-setup',
    loadChildren: () => import('./saf-setup/saf-setup.module').then(m => m.SafSetupModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'account',
    loadChildren: () => import('./accountmanagement/accountmanagement.module').then(m => m.AccountmanagementModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'accounting',
    loadChildren: () => import('./accounting/accounting.module').then(m => m.AccountingModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'wallet',
    loadChildren: () => import('./wallet/wallet.module').then(m => m.WalletModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'ACL',
    loadChildren: () => import('./acl/acl.module').then(m => m.ACLModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'report',
    loadChildren: () => import('./report/report.module').then(m => m.ReportModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'mobile-tower',
    loadChildren: () => import('./mobile-tower/mobile-tower.module').then(m => m.MobileTowerModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'operations',
    loadChildren: () => import('./operations/operations.module').then(m => m.OperationsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'trade-licence',
    loadChildren: () => import('./trade-licence/trade-licence.module').then(m => m.TradeLicenceModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'shop-rent',
    loadChildren: () => import('./shop-rent/shop-rent.module').then(m => m.ShopRentModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'solid-waste',
    loadChildren: () => import('./solidwaste/solidwaste.module').then(m => m.SolidwasteModule),
    canActivate: [AuthGuard]
  },

  {
    path: 'report-management',
    loadChildren: () => import('./reportmanagement/reportmanagement.module').then(m => m.ReportmanagementModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'water-bill-management',
    loadChildren: () => import('./waterbillmanagement/waterbillmanagement.module').then(m => m.WaterbillmanagementModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'update',
    loadChildren: () => import('./update/update.module').then(m => m.UpdateModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'BirthCertificate',
    loadChildren: () => import('./birthcertificate/birthcirtificate.module').then(m => m.BirthcirtificateModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'admin',
    loadChildren: ()=>AdminModule,
    canActivate: [AuthGuard]
  },
  {
    path: '**',
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
  },
  {
    path:'waterchargemgmt',
    loadChildren: () => import('./waterchargemgmt/waterchargemgmt.module').then(m => m. WaterchargemgmtModule),
    canActivate: [AuthGuard]
  }


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]

})
export class AppRoutingModule { }
