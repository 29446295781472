import { HeaderComponent } from './dashboard/header/header.component';
import { FooterComponent } from './dashboard/footer/footer.component';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { environment as environment } from '../environments/environment';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CommonModule, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HttpClientModule,HTTP_INTERCEPTORS } from '@angular/common/http';
import { BackButtonDisableModule } from 'angular-disable-browser-back-button';
import { NgxSpinnerModule } from "ngx-spinner";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PagerService } from './services/pager.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { I18nModule } from './i18n/i18n.module';
import { SelectLngModule } from './select-lng/select-lng.module';
import { AgmCoreModule } from '@agm/core';
import { DataEntryComponent } from './user_management/data-entry/data-entry.component';
import { UserTypeComponent } from './user_management/user-type/user-type.component';
import { AddDataEntryComponent } from './user_management/add-data-entry/add-data-entry.component';
import { EditDataEntryComponent } from './user_management/edit-data-entry/edit-data-entry.component';
import {EditorModule, TINYMCE_SCRIPT_SRC} from '@tinymce/tinymce-angular';
import {ErrorHandler, Injectable} from '@angular/core';
import{WaterchargemgmtModule}from '../app/waterchargemgmt/waterchargemgmt.module'

import { ExampleInterceptorInterceptor } from './example-interceptor.interceptor';
import { ViewDataEntryComponent } from './user_management/view-data-entry/view-data-entry.component';



// import { CarouselModule } from 'ngx-bootstrap/carousel';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler{
  handleError(error: any):void{
  const chunkFailedMessage = /Loading chunk [\d] + failed/;

  if(chunkFailedMessage.test(error.message)){
    window.location.reload();
  }
  }
}


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    DataEntryComponent,
    UserTypeComponent,
    AddDataEntryComponent,
    EditDataEntryComponent,
    ViewDataEntryComponent,
    
   
   
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    BackButtonDisableModule.forRoot(),
    I18nModule,
    SelectLngModule,
    EditorModule,
    WaterchargemgmtModule
    // CarouselModule
  ],
  providers: [
    {provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: LocationStrategy, useClass: HashLocationStrategy }, PagerService,
    { provide: TINYMCE_SCRIPT_SRC, useValue:'tinymce/tinymce.min.js'},
    { provide: HTTP_INTERCEPTORS, useClass: ExampleInterceptorInterceptor, multi: true },

  ],

  bootstrap: [AppComponent],
  // exports: [SelectLangComponent]
})
export class AppModule {
  constructor() {
  }
}
